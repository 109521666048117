import React from 'react'
import Container from './container'

import * as styles from './snippets-hero.module.css'

const SnippetsHero = ({ snippets }) => {
  if (!snippets) return null
  if (!Array.isArray(snippets)) return null
  let lastSnippetIndex = snippets.length - 1

  return (
    <Container>
      <ul className={styles.snippetList}>
        {snippets.map((snippet, index) => {
          let dateString = snippet.date.split("T")[0]
          return (
            <li className={styles.snippetItem}>
                <div className={styles.snippetHeader}>
                  <h3 className={styles.title}>{snippet.title}</h3>
                  <small className={styles.meta}>{dateString}</small>
                </div>
                <div>{snippet.text}</div>
                {index !== lastSnippetIndex && (<div className={styles.divider}></div>)}
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default SnippetsHero
