import React from 'react'

import * as styles from './simple-page-header.module.css'

const SimplePageHeader = ({ title, description }) => {
  return (
    <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <body className={styles.content}>{description}</body>
    </div>
  )
}

export default SimplePageHeader
