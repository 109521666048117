import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import SnippetsHero from '../components/snippets-hero'
import SimplePageHeader from '../components/simple-page-header'

class SnippetsIndex extends React.Component {
  render() {
    const snippets = get(this, 'props.data.allContentfulSnippet.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Snippets" />
        <SimplePageHeader title="Snippets" description="Quick thoughts and ideas."/>
        <SnippetsHero snippets={snippets} />
      </Layout>
    )
  }
}

export default SnippetsIndex

export const pageQuery = graphql`
  query SnippetsIndexQuery {
    allContentfulSnippet(sort: {fields: date, order: DESC}) {
      nodes {
        date
        title
        text
      }
    }
  }
`
